import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import "./terms.scss";
const TermsOfUse = () => {
  return (
    <>
      <SEO title="Terms Of Use" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="תנאי שימוש" page="תנאי שימוש" />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="privacy-policy-content">
      <section>
        <h2>כללי</h2>
        <p>1.1 תנאי שימוש אלה ("תנאי השימוש") מסדירים את השימוש באתר האינטרנט של חברת WeSeo ("החברה"), בכתובת www.weseo.com (או כל כתובת חלופית שתיקבע בעתיד), ובדשבורד המופעל במסגרת האתר.</p>
        <p>1.2 הגדרת הדשבורד:</p>
        <p>הדשבורד הוא מערכת אינטראקטיבית המרכזת מידע שנמסר על ידי המשתמש, מידע שנאסף מצדדים שלישיים, ומידע שנאסף על ידי החברה באמצעות כלים טכנולוגיים שונים. הדשבורד מאפשר למשתמשים לנתח את המידע, לבצע השוואות ולהפיק תובנות הנוגעות לדומיינים, אתרים, מתחרים ומילות מפתח.</p>
        <p>הדשבורד זמין באתר, בתתי-הדומיינים, ובכל ממשק אחר שמפעילה החברה, ומהווה חלק בלתי נפרד מכלל השירותים הניתנים על ידי החברה.</p>
        <p>1.3 תנאי שימוש אלה מהווים הסכם משפטי מחייב בין המשתמש לבין החברה.</p>
        <p>1.4 בכל מקרה של סתירה בין תנאי השימוש לבין מסמכים או פרסומים אחרים, תנאי השימוש יגברו.</p>
        <p>1.5 השימוש באתר ובדשבורד מהווה הסכמה מלאה מצד המשתמש לתנאים המפורטים במסמך זה. אם אינך מסכים לתנאי השימוש, עליך להימנע מכל שימוש באתר, בדשבורד ובשירותי החברה.</p>
        <p>1.6 תנאי השימוש משלימים את מדיניות הפרטיות של החברה, ומהווים חלק בלתי נפרד ממנה.</p>
        <p>1.7 החברה רשאית לשנות או לעדכן את תנאי השימוש מעת לעת לפי שיקול דעתה הבלעדי, והשינויים ייכנסו לתוקף עם פרסומם באתר.</p>
        <p>1.8 תנאי השימוש חלים על השימוש באתר ובדשבורד מכל מחשב, טלפון סלולרי, טאבלט או מכשיר תקשורת אחר, וכן באמצעות רשת האינטרנט או כל רשת תקשורת אחרת.</p>
        <p>1.9 השימוש במידע, במוצרים ו/או בשירותים כלשהם באמצעות הדאשבורד ובכלל הינם באחריותך הבלעדית ובכפוף להוראות תנאי שימוש אלו.</p>
        <p>1.10 הנך להסכים בזאת במפורש כי הבעלים פטורים לחלוטין מכל אחריות, נזק או אי נוחות, לך או לאחרים, לגבי כל היבט של שימוש באפליקציה/במוצרים/בשירותים באמצעות הדאשבורד או שיסופקו לך באמצעותו והנך לקחת את כל האחריות לכך על עצמך בלבד ולבצע את כל הבדיקות הדרושות.</p>
        <p>1.11 כל האמור בלשון זכר מתייחס לנשים וגברים כאחד, והאמור בלשון יחיד מתייחס גם לרבים ולהפך.</p>
      </section>

      <section>
        <h2>שימוש באתר ובדשבורד</h2>
        <p>2.1 השימוש באתר ובדשבורד ניתן "כמות שהוא" (as-is). החברה אינה מתחייבת לזמינות רציפה של השירותים או המידע המוצג.</p>
        <p>2.2 במהלך ההרשמה והשימוש באתר ובדשבורד, המשתמש יתבקש לספק פרטים אישיים כגון שם, דוא"ל, מספר טלפון, מידע עסקי, ופרטים נוספים הנדרשים לשירות. אי מסירת פרטים מלאים ונכונים עלולה למנוע שימוש בשירותים.</p>
        <p>2.3 החברה רשאית לשנות את אמצעי הזיהוי לכניסה לשירותים, כולל אימות במסרון, זיהוי באמצעות רשתות חברתיות או כל אמצעי אחר שתבחר.</p>
        <p>2.4 השימוש באתר ובדשבורד נועד לצרכים חוקיים בלבד, והמשתמש מתחייב לפעול בהתאם להוראות הדין ותנאי השימוש.</p>
        <p>2.5 החברה אינה אחראית לנזקים ישירים, עקיפים או נסיבתיים שייגרמו כתוצאה מהשימוש באתר ובדשבורד, מהסתמכות על המידע המוצג או מאובדן נתונים.</p>
        <p>2.6 הבעלים של המערכת אינם מתחייבים לשמור תכנים שהוזנו למערכת או שנוצרו באמצעותה, כגון גרפים, דוחות או נתוני השוואה. האחריות על שמירת תכנים אלו באמצעים חיצוניים היא עליך בלבד, ואינה תלויה בבעלים, במערכת או בכל גורם הקשור אליהם.</p>
        <p>שימור תכנים חיצוני יעשה בכפוף להוראות כל דין ותנאי השימוש במערכת זו.</p>
      </section>

      <section>
        <h2>אחריות המשתמש</h2>
        <p>3.1 המשתמש אחראי בלעדית לכל פעולה שתתבצע באמצעות חשבונו באתר או בדשבורד.</p>
        <p>3.2 המשתמש מתחייב שלא לעשות שימוש במידע, בשירותים או בדשבורד למטרות בלתי חוקיות או פוגעניות, וכן שלא להפר את תנאי השימוש או לפגוע בזכויות צדדים שלישיים.</p>
        <p>3.3 המשתמש מתחייב להגן על פרטי הכניסה לחשבונו ולא לאפשר גישה בלתי מורשית אליו.</p>
      </section>

      <section>
        <h2>עיבוד מידע ושיתוף עם צדדים שלישיים</h2>
        <p>4.1 החברה מעבדת את המידע שמספק המשתמש ומידע שנאסף מצדדים שלישיים לצורך הצגת ניתוחים, השוואות ותובנות במסגרת הדשבורד.</p>
        <p>4.2 החברה רשאית לשתף מידע אישי עם צדדים שלישיים לצורך:</p>
        <ul>
          <li>אספקת שירותים ותפעול הדשבורד (כגון ספקי אחסון ענן ומערכות סליקה).</li>
          <li>הרחבת נתונים וניתוחים עסקיים.</li>
          <li>עמידה בדרישות חוקיות, כגון צווים שיפוטיים או דרישות רשות מוסמכת.</li>
          <li>מניעת שימוש לרעה והגנה מפני פעולות בלתי חוקיות.</li>
        </ul>
        <p>4.3 החברה רשאית לשתף נתונים אנונימיים או סטטיסטיים לצורכי מחקר ושיפור השירותים.</p>
        <p>4.4 החברה אינה אחראית לנזקים שייגרמו כתוצאה משימוש שגוי של צדדים שלישיים במידע ששיתפה עימם.</p>
      </section>

      <section>
        <h2>קניין רוחני</h2>
        <p>5.1 כל זכויות הקניין הרוחני באתר, בדשבורד ובשירותים הניתנים בו, שייכות לחברה או לצדדים שלישיים שהסמיכו אותה.</p>
        <p>5.2 אין להעתיק, לשכפל, לשנות או להפיץ את התכנים ללא אישור מפורש מראש ובכתב מהחברה.</p>
      </section>

      <section>
        <h2>שינויים באתר ובדשבורד</h2>
        <p>6.1 החברה רשאית לשנות, לעדכן או להסיר שירותים, תכנים ופונקציות באתר ובדשבורד ללא הודעה מראש.</p>
        <p>6.2 שינויים אלו עשויים להיות כרוכים בתקלות או אי נוחות זמנית. החברה לא תהיה אחראית לנזקים שייגרמו כתוצאה מכך.</p>
      </section>

      <section>
        <h2>ביטול עסקה</h2>
        <p>7.1 אפשרות ביטול</p>
        <p>ניתן לבטל עסקה בכל עת.</p>
        <p>7.2 אופן הגשת בקשה לביטול</p>
        <p>
          לבקשת ביטול עסקה, יש לפנות באחת מהדרכים הבאות:
          <br />
          שליחת מייל בכתובת: <strong>weseo-supp@gmail.com</strong>
          <br />
          שימוש בכפתור פתח שיחה וליצור קשר עם נציג שירות.
        </p>
        <p>7.3 אופן החיוב</p>
        <p>החיוב במסלולים מתבצע באמצעות הוראת קבע (תשלום חודשי מתחדש). במקרה של ביטול, הלקוח יחויב בסכום יחסי בהתאם לשימוש מאז מועד החיוב האחרון.</p>
        <p>7.4 מועד הביצוע</p>
        <p>ביטול העסקה יבוצע תוך יום עסקים אחד מרגע קבלת הבקשה לביטול.</p>
      </section>

      <section>
        <h2>פטור מאחריות</h2>
        <p>8.1 הדאשבורד והתכנים הכלולים בו מוצעים לשימוש כפי שהם ("As Is"), ללא כל התחייבות או מצג מצד הבעלים לגבי התאמתם לצרכיך ו/או מטרותיך.</p>
        <p>8.2 הבעלים אינם נושאים באחריות כלשהי, בין אם חוזית, נזיקית, או אחרת, בנוגע לשימוש בדאשבורד ו/או בכלים הכלולים בו. כמו כן, הבעלים אינם אחראים לכל מניעה, הפרעה, או תקלה בשימוש בדאשבורד מכל סיבה שהיא.</p>
        <p>8.3 הבעלים אינם אחראים לתכנים שיוזנו או יימסרו על ידך, לרבות חוקיותם, אמינותם, דיוקם, שלמותם, או כל נזק, הפסד, או עגמת נפש שייגרמו לך או לצד שלישי כלשהו עקב תכנים אלו, לרבות קבצים מצורפים.</p>
        <p>8.4 הבעלים אינם נושאים באחריות לכל נזק שנגרם עקב הורדת נתונים מהדאשבורד, שימוש בקישורים הכלולים בו, או הצגת תכנים הקשורים אליו. האחריות הבלעדית לכל פעולה שתעשה בתכנים ו/או בפרסומם מוטלת עליך בלבד, ואתה מתחייב בזאת לשפות את הבעלים בגין כל נזק או הוצאה שייגרמו כתוצאה מכך.</p>
        <p>8.5 הבעלים אינם מתחייבים לשמירת תכנים שהוזנו לדאשבורד או שהורדו ממנו. האחריות לשמירת תכנים אלו, באמצעים פרטיים ומוליכים חוקיים, מוטלת עליך בלבד.</p>
        <p>8.6 הנך מצהיר ומאשר כי השימוש בדאשבורד, לרבות כל אחריות לסיכונים או נזקים כתוצאה משימוש זה, מוטל עליך בלבד. הבעלים לא יישאו באחריות לנזקים מכל סוג, לרבות נזקים פיזיים, כלכליים, עגמת נפש, או תוצאות אחרות. כמו כן, הנך מתחייב להימנע משימוש בדאשבורד במהלך נהיגה, שכן פעולה זו אסורה ומסכנת את חייך וחיי סביבתך.</p>
      </section>

      <section>
        <h2>מדיניות פרטיות</h2>
        <p>9.1 השימוש באתר ובדשבורד כפוף למדיניות הפרטיות של החברה, המפרטת את אופן איסוף ועיבוד המידע.</p>
        <p>9.2 מדיניות הפרטיות זמינה באתר, ומהווה חלק בלתי נפרד מתנאי השימוש.</p>
      </section>

      <section>
        <h2>דין וסמכות שיפוט</h2>
        <p>10.1 תנאי השימוש כפופים לדיני מדינת ישראל בלבד.</p>
        <p>10.2 כל מחלוקת תידון בבתי המשפט המוסמכים בעיר תל-אביב.</p>
      </section>

      <p>
        <strong>לשאלות או פניות:</strong> <br />
        דוא"ל: <strong>weseo-supp@gmail.com</strong>
      </p>
    </div>
                </div>
              </div>
            </div>
        </div>

        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default TermsOfUse;
