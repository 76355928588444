import React from "react";

const SectionTitle = ({
  subtitle,
  title,
  description,
  textAlignment,
  textColor,
  imageSrc,
}) => {
  return (
    <div className={`section-heading ${textAlignment} ${textColor}`}>
      <div
        className="subtitle"
        dangerouslySetInnerHTML={{ __html: subtitle }}
      ></div>
      <h2 className="title" dangerouslySetInnerHTML={{ __html: title }}></h2>
      <p dangerouslySetInnerHTML={{ __html: description }}></p>
    </div>
    // {imageSrc && (
    //   <div className="dashboard-image">
    //     <img src={imageSrc} alt="Dashboard Illustration" />
    //   </div>
    // )}
    // </div>
  );
};

export default SectionTitle;
