import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";

const PrivacyPolicy = () => {
  return (
    <>
      <SEO title="Privacy Policy" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="מדיניות פרטיות" page="מדיניות פרטיות" />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="privacy-policy-content">
                  <div className="section-title">
                    <h5 className="title">
                      מסמך הפרטיות פורסם ב-1.11.2024
                    </h5>
                  </div>
<section>
  <h2>1. כללי</h2>
  <p>
    WeSeo ("החברה" או "אנחנו") מחויבת לשמירה על פרטיות משתמשיה ולניהול שקוף של המידע הנאסף. מדיניות זו מתארת את סוגי המידע
    שאנו אוספים, השימושים בו, וזכויותיך לעיין בו, לעדכנו או למחוק אותו.
  </p>
  <p>
    הדשבורד הוא ממשק מרכזי המוצע על ידי החברה, שבו מרוכזים כל הנתונים שהמשתמשים מספקים לנו באופן ישיר או שנאספים מצדדים
    שלישיים בשמם. הדשבורד משמש פלטפורמה להצגת ניתוחים, עיבודים ומידע מחושב, המבוססים על נתוני הדומיינים, האתרים, מילות
    המפתח, המתחרים ופרטי העסק של המשתמש. המידע המוצג בדשבורד זמין בכל רחבי האתר של WeSeo ובתתי-הדומיינים השייכים לחברה.
  </p>
  <p>
    על ידי שימושך באתר ובדשבורד של WeSeo, אתה מסכים למדיניות פרטיות זו. אם אינך מסכים לתנאי המדיניות, אנא הימנע משימוש
    בשירותים המוצעים. מדיניות זו מהווה חלק בלתי נפרד מתנאי השימוש של האתר.
  </p>
</section>

<section>
  <h2>2. מידע שנאסף</h2>

  <h3>2.1 מידע אישי שמסרת לנו ישירות</h3>
  <p>בעת הרשמה לשירותים או שימוש בדשבורד, אנו אוספים מידע אישי שהמשתמש מספק, כולל:</p>
  <ul>
    <li>שם פרטי ומשפחה</li>
    <li>כתובת דוא"ל</li>
    <li>מספר טלפון</li>
    <li>מידע עסקי, כמו שם העסק, תחום פעילותו, כתובת האתר או הדומיין</li>
    <li>פרטים נוספים שתספק בעת השימוש בשירותים</li>
  </ul>

  <h3>2.2 מידע המתקבל מצדדים שלישיים</h3>
  <p>
    אנו עשויים לקבל מידע נוסף על דומיינים, אתרים, פרטים עסקיים ומילות מפתח ממשתמשים דרך ספקי שירות צד שלישי. המידע כולל נתונים
    שהמשתמש מסר או שנאספו מצדדים שלישיים העובדים בשיתוף פעולה איתנו.
  </p>

  <h3>2.3 מידע טכני וסטטיסטי</h3>
  <p>
    במהלך שימושך באתר ובדשבורד, נאספים נתונים טכניים שאינם מזהים אותך באופן אישי, כגון דפים שנצפו, כתובת IP, משך זמן השהייה
    באתר, ודפוסי שימוש אחרים.
  </p>

  <h3>2.4 עוגיות (Cookies)</h3>
  <p>
    לצורך שיפור השירות, אבטחת מידע וניהול חוויית המשתמש, האתר משתמש בקבצי עוגיות, כמפורט בסעיף 5 להלן.
  </p>
</section>

<section>
  <h2>3. מטרות השימוש במידע</h2>
  <p>השימוש במידע נועד למטרות הבאות:</p>
  <ul>
    <li>לאפשר לך להשתמש באתר ובדשבורד</li>
    <li>לספק לך שירותים מותאמים אישית ותמיכה טכנית</li>
    <li>לתקשר איתך בנוגע לשירותים</li>
    <li>לשפר את השירותים והתכנים המוצעים</li>
    <li>למנוע שימוש לרעה או פעילות לא חוקית</li>
    <li>לעמוד בדרישות חוקיות וצווים משפטיים</li>
  </ul>
  <p>בנוסף, אנו עשויים לעשות שימוש במידע אנונימי לצרכי מחקר, סטטיסטיקה ושיפור השירותים.</p>
</section>

<section>
  <h2>4. שיתוף מידע עם צדדים שלישיים</h2>
  <p>
    על מנת להעניק לך את השירותים, לתפעל את האתר ואת הדשבורד, לשמור על האינטרסים הלגיטימיים שלנו, ולעמוד בחובות החלות עלינו
    מכוח דין, אנו עשויים במקרים מסוימים לחלוק את המידע האישי שלך עם צדדים שלישיים. שיתוף המידע יתבצע רק במקרים הבאים:
  </p>
  <ul>
    <li>לצורך אספקה תקינה של השירותים והתכנים המוצעים.</li>
    <li>לצורך הרחבת נתונים אודות העסק שלך.</li>
    <li>מחקר ואנליזה.</li>
    <li>מניעת שימוש לרעה.</li>
    <li>במקרה של דרישות חוקיות.</li>
    <li>בהקשר של מחלוקות משפטיות.</li>
    <li>למניעת נזק.</li>
    <li>שיתוף מידע סטטיסטי או אנונימי.</li>
    <li>במסגרת ארגון מחדש של החברה.</li>
    <li>במקרה של חשד לשימוש לרעה.</li>
    <li>בהסכמתך המפורשת.</li>
  </ul>
  <p>
    התחייבויות אלה אינן מונעות פעולות ביקורת של רשויות מוסמכות, כגון רשות המסים, בהתאם לדרישות החוק.
  </p>
  <p>
    צדדים שלישיים המקבלים מידע אישי עשויים להימצא במדינות שבהן רמת ההגנה על מידע אישי שונה ולעיתים פחותה מזו הנהוגה בישראל.
    אנו נעביר מידע לצדדים שלישיים אלו רק אם הם מסוגלים לספק אמצעי הגנה נאותים המבטיחים כי המידע האישי יאוחסן ויטופל בצורה
    מאובטחת.
  </p>
</section>

<section>
  <h2>5. עוגיות (Cookies)</h2>
  <p>
    האתר עושה שימוש בקבצי עוגיות לצורך תפעול שוטף ואיסוף נתונים סטטיסטיים. עוגיות הן קבצי טקסט שנשמרים במחשבך ומאפשרים לנו
    להתאים את השירותים לצרכים שלך. ניתן לחסום עוגיות בהגדרות הדפדפן, אך פעולה זו עשויה להגביל את יכולת השימוש באתר.
  </p>
</section>

<section>
  <h2>6. אבטחת מידע</h2>
  <p>
    אנו נוקטים באמצעים מתקדמים לאבטחת המידע שלך, אך לא ניתן להבטיח הגנה מוחלטת מפני פרצות אבטחה. במקרה של דליפת מידע, נפעל
    בהתאם להוראות החוק ונודיע לך במידת הצורך.
  </p>
</section>

<section>
  <h2>7. זכות לעיון ותיקון מידע</h2>
  <p>
    באפשרותך לעיין במידע שנאסף עליך, לעדכנו או לבקש את מחיקתו, בהתאם לחוק. לפניות, ניתן ליצור קשר בכתובת: 
    <strong>weseo-supp@gmail.com</strong>
  </p>
</section>

<section>
  <h2>8. שינויים במדיניות הפרטיות</h2>
  <p>מדיניות זו עשויה להשתנות מעת לעת. במקרה של עדכון מהותי, תפורסם הודעה באתר.</p>
</section>

<section>
  <h2>9. שמירת מידע</h2>
  <p>המידע שלך יישמר כל עוד הוא נדרש למתן השירותים או לצרכים עסקיים אחרים, בכפוף לחוק.</p>
</section>

<section>
  <h2>10. קישורים לאתרים חיצוניים</h2>
  <p>
    האתר עשוי לכלול קישורים לאתרים שאינם בשליטתנו. מדיניות הפרטיות של אתרים אלו כפופה לתנאים הנהוגים בהם, ואינה באחריותנו.
  </p>
</section>

<p><strong>לשאלות או הבהרות נוספות, צור קשר: weseo-supp@gmail.com</strong></p>
    </div>
              </div>
            </div>
          </div>
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default PrivacyPolicy;
